.content {
  height: 45vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;
}
.contentTitle {
  text-transform: uppercase;
  color: #383c42;
}
.contentDescription {
  color: #383c42;
}
.projectList {
  text-decoration: none;
  list-style: none;
}
.projectItem {
  text-decoration: none;
}
